import APIClient from './client'
import { camelizeKeys } from 'humps'

const getAllOrgs = () => {
  return APIClient().get(`/v1/orgs`)
    .then( response => camelizeKeys(response.data))
}

const OrgsApis = { getAllOrgs }
export default OrgsApis
