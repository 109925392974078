import React from 'react';
import { connect } from "react-redux";
import { isNil, get } from 'lodash'
import { withRouter } from "react-router-dom";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import Cookies from 'universal-cookie';

import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import {
  Settings,
  Users
} from "react-feather";

/* Actions */
import { loadOrgs } from '../redux/actions/orgsActions'
import { logOutUser } from '../redux/actions/authenticationActions'

import styles from './Navbar.module.css'


/* Selectors */
const orgsLoadingSelector = (state) =>
  get(state, 'orgs.loading', true)

const orgsSelector = (state) =>
  get(state, 'orgs.data')

const currentUserSelector = (state) =>
  get(state, 'authentication', null)
/* END Selectors */


const logOut = (ev) => {
  ev.preventDefault()
  const cookies = new Cookies();
  cookies.remove('idToken',{ path: '/' })
  cookies.remove('email',{ path: '/' })
  window.location = '/'
}

const renderOrgs = (orgsAreLoading, orgs, orgId, history) => {

  return (
    <UncontrolledDropdown nav inNavbar className="mr-2">
      { orgsAreLoading && (
        <DropdownToggle nav caret>
          <Users size={18} className="align-middle mr-2" />
          <span className="text-dark">Organizations are loading ...</span>
        </DropdownToggle>
      )}
      { !orgsAreLoading && orgs && orgId && (
        <DropdownToggle nav caret>
          <Users size={18} className="align-middle mr-2" />
          <span className="text-dark">{orgs.find((el) => parseInt(el.id) === parseInt(orgId)).name}</span>
        </DropdownToggle>
      )}
      { !orgsAreLoading && orgs && orgId && (
        <DropdownMenu right >
          <div className="dropdown-menu-header position-relative">
            Select Organization
          </div>
          {
            orgs.map(item => (
              <DropdownItem key={item.id} onClick={() => history.push("/org/" + item.id ) }>
                { parseInt(item.id) === parseInt(orgId) && (
                  <Users size={18} className="align-middle mr-2" />
                )}
                <span className="align-middle">{item.name}</span>
              </DropdownItem>
            ))
          }
        </DropdownMenu>
      )}
    </UncontrolledDropdown>
  )
}

class NavbarComponent extends React.Component {
  componentDidMount() {
    if(!this.props.orgs){
      this.props.fetchOrgs()
    }
  }

  componentDidUpdate(prevProps) {
    const orgId = this.props.match.params.orgId
    if(this.props.orgs && orgId === undefined) {
      this.props.history.push("/org/" + this.props.orgs[0].id );
    }
  }

  render() {
    const orgId = this.props.match.params.orgId
    const {
      orgsAreLoading,
      orgs,
      history,
      currentUser
    } = this.props

    return (
      <Navbar color="white" light expand>
        <span
          className="sidebar-toggle d-flex mr-2"
          onClick={() => {
            this.props.dispatch(toggleSidebar());
          }}
        >
          <i className="hamburger align-self-center" />
        </span>

        <Collapse navbar>
          <Nav className="ml-auto" navbar>

            {renderOrgs(orgsAreLoading, orgs, orgId, history)}

            <UncontrolledDropdown nav inNavbar>
              <span className="d-inline-block d-sm-none">
                <DropdownToggle nav caret>
                  <Settings size={18} className="align-middle" />
                </DropdownToggle>
              </span>
              <span className="d-none d-sm-inline-block">
                <DropdownToggle nav caret>
                  <img
                    src={isNil(currentUser) ? '--' : currentUser.imageUrl}
                    className="avatar img-fluid rounded-circle mr-1"
                    alt={isNil(currentUser) ? '--' : currentUser.name}
                  />
                  <span className="text-dark">{isNil(currentUser) ? '--' : currentUser.name}</span>
                </DropdownToggle>
              </span>
              <DropdownMenu right className={styles.googleLogoutButton}>
                <DropdownItem key='log-out' onClick={ logOut }>
                  <span className="align-middle">Log Out</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>

      </Navbar>
    );
  }
};



const mapStateToProps = (state, ownProps) => ({
  orgsAreLoading: orgsLoadingSelector(state),
  orgs: orgsSelector(state),
  currentUser: currentUserSelector(state)
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchOrgs: () => dispatch(loadOrgs()),
    logOutUser: () => dispatch(logOutUser()),
    dispatch
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavbarComponent)
)
