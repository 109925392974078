import {
  AGENCY_FORM_TOGGLE,
  AGENCY_FORM_RESET,
  LOADING_AGENCY,
  LOADING_AGENCY_SUCCESS,
  SAVING_AGENCY,
  SAVED_AGENCY_SUCCESS,
  SAVED_AGENCY_FAILURE
} from '../constants/agencyFormTypes'

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case AGENCY_FORM_RESET:
      return {
        agencyId: null,
        agency: null,
        toggle: false,
        formState: {}
      }
    case AGENCY_FORM_TOGGLE:
      return {
        ...state,
        agency: null,
        agencyId: actions.agencyId,
        toggle: actions.toggle
      }
      case SAVING_AGENCY:
        return {
          ...state,
          formState: {
            inProgress: true,
            success: null,
            errors: null
          }
        }
      case SAVED_AGENCY_SUCCESS:
        return {
          ...state,
          formState: {
            inProgress: false,
            success: true,
            data: actions.data ? actions.data : {},
            errors: null
          }
        }
      case LOADING_AGENCY:
        return {
          ...state,
          agency: {
            loading: true,
            success: null,
            data: null
          }
        }
      case LOADING_AGENCY_SUCCESS:
        return {
          ...state,
          agency: {
            loading: false,
            success: true,
            data: actions.data ? actions.data : [],
            errors: null
          }
        }
      case SAVED_AGENCY_FAILURE:
        return {
          ...state,
          formState: {
            inProgress: false,
            success: false,
            errors: actions.errors
          }
        }
    default:
      return state
  }
}
