import {
  LOADING_FB_AD_ACCOUNTS,
  LOADING_FB_AD_ACCOUNTS_SUCCESS,
  LOADING_FB_AD_ACCOUNTS_FAILURE
} from '../constants/fbAdAccountsTypes'

const initialState = {
  loading: true,
  success: null,
  data: null,
  errors: null
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case LOADING_FB_AD_ACCOUNTS:
      return { ...state, loading: true }
    case LOADING_FB_AD_ACCOUNTS_SUCCESS:
      return {
        ...state,
        success: true,
        data: actions.data ? [...actions.data] : [],
        loading: false
      }
    case LOADING_FB_AD_ACCOUNTS_FAILURE:
      return {
        ...state,
        success: false,
        errors: actions.errors,
        loading: false
      }
    default:
      return state
  }
}
