import APIClient from './client'
import { camelizeKeys } from 'humps'

const getAllXpoLocationsUnderBrand = ( brandId ) => {
  return APIClient().get(`/v1/xpo_admin/brands/${brandId}/locations?available=true`)
    .then( response => camelizeKeys(response.data))
}

const xpoAdminLocations = { getAllXpoLocationsUnderBrand }
export default xpoAdminLocations
