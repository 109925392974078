import ActionCable from 'actioncable';
import { find } from 'lodash'
import Cookies from 'universal-cookie';

const API_WS_ROOT = process.env.REACT_APP_CONNECTOR_WS_HOST || 'ws://localhost:3001/cable';

const cookies = new Cookies();

const actionCableMiddleware = () => {
  const idToken = cookies.get('idToken',{ path: '/' })
  const email = cookies.get('email',{ path: '/' })
  const urlParams = `idToken=${idToken}&email=${email}`

  const cable = ActionCable.createConsumer(API_WS_ROOT + '?' + urlParams);


  return ({ dispatch, getState }) => next => (action) => {
    if (typeof(action) === 'function') {
      return next(action)
    }

    const {
      channel,
      params,
      leave,
    } = action;
    let { received } = action;

    if (!channel) {
      return next(action);
    }

    if (leave) {
      const subscription = find(
        cable.subscriptions.subscriptions,
        sub => sub.identifier === JSON.stringify({ channel, params }),
      );

      return cable.subscriptions.remove(subscription);
    }

    if (typeof(received) === 'string') {
      received = result => dispatch({ type: received, result })
    }

    return cable.subscriptions.create({ channel, params }, { received });
  };
}

export default actionCableMiddleware
