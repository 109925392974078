import APIClient from './client'
import { camelizeKeys } from 'humps'

const getAllFbPages = () => {
  return APIClient().get(`/v1/fb_pages`)
    .then( response => camelizeKeys(response.data))
}
const fbPagesApi = { getAllFbPages }

export default fbPagesApi 
