import React from "react";

import Wrapper from "../components/Wrapper";
import Main from "../components/Main";
import Content from "../components/Content";
import Footer from "../components/Footer";

const MovieScreen = ({ children }) => (
  <React.Fragment>
    <Wrapper>
      <Main>
        <Content>{children}</Content>
        <Footer />
      </Main>
    </Wrapper>
  </React.Fragment>
)

export default MovieScreen;
