import { camelizeKeys } from 'humps'
import { camelCase } from 'lodash'

import {
  SYNC_WITH_BM_NOTIFICATION_RECEIVED
} from '../constants/syncWithBmTypes'

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case SYNC_WITH_BM_NOTIFICATION_RECEIVED:
      const data = camelizeKeys(actions.data)
      return {
        ...state,
        [camelCase(data.type)]: {
          syncedAt: data.syncedAt
        }
      }
    default:
      return state
  }
}
