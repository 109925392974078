import {
  SIGN_IN_USER_USER,
  SIGN_OUT_USER,
} from '../constants/authenticationTypes'

export function logInUser( userData ) {
  return dispatch => {
    return dispatch({ type: SIGN_IN_USER_USER, userData })
  }
}

export function logOutUser() {
  return dispatch => {
    dispatch({ type: SIGN_OUT_USER })
  }
}
