import { camelizeKeys } from 'humps'

import {
  GLOBAL_CONNECTIONS_CSV_RECEIVED
} from '../constants/csvExportTypes'

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case GLOBAL_CONNECTIONS_CSV_RECEIVED:
      const data = camelizeKeys(actions.data)
      return {
        ...state,
        [data.orgId]: {
          cacheKey: data.cacheKey,
          createdAt: data.createdAt,
        }
      }
    default:
      return state
  }
}
