import APIClient from './client'
import { camelizeKeys } from 'humps'

const getAllTTAdvertisersUnderBrand = ( brandId ) => {
  return APIClient().get(`/v1/tik_tok/brands/${brandId}/advertisers`)
    .then( response => camelizeKeys(response.data))
}

const ttAdvertisersAPI = { getAllTTAdvertisersUnderBrand }
export default ttAdvertisersAPI
