import {
  SIGN_IN_USER_USER
} from '../constants/authenticationTypes'

export default function reducer(state = null, actions) {
  switch (actions.type) {
    case SIGN_IN_USER_USER:
      return {
        ...state,
        id: actions.userData.id,
        name: actions.userData.name,
        imageUrl: actions.userData.imageUrl,
        email: actions.userData.email,
        idToken: actions.userData.idToken
      }
    default:
      return state
  }
}
