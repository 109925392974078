import {
  LOADING_FB_PAGES,
  LOADING_FB_PAGES_SUCCESS,
  LOADING_FB_PAGES_FAILURE
} from '../constants/fbPagesTypes'

const initialState = {
  loading: true,
  success: null,
  data: null,
  errors: null
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case LOADING_FB_PAGES:
      return { ...state, loading: true }
    case LOADING_FB_PAGES_SUCCESS:
      return {
        ...state,
        success: true,
        data: actions.data ? [...actions.data] : [],
        loading: false
      }
    case LOADING_FB_PAGES_FAILURE:
      return {
        ...state,
        success: false,
        errors: actions.errors,
        loading: false
      }
    default:
      return state
  }
}
