import React from "react";

import { Col, Container, Row } from "reactstrap";

import Wrapper from "../components/Wrapper";
import Main from "../components/Main";
import Footer from "../components/Footer";
import styles from './Auth.module.css'

const Auth = ({ children }) => (
  <React.Fragment>
    <Wrapper>
      <Main>
        <Container className={styles.container + " d-flex flex-column"}>
          <Row className="h-100">
            <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
              <div className="d-table-cell align-middle">{children}</div>
            </Col>
          </Row>
        </Container>
        <Footer className={styles.footer}/>
      </Main>
    </Wrapper>

  </React.Fragment>
);

export default Auth;
