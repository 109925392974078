export const LOADING_AGENCY = 'agency_form/LOADING_AGENCY '
export const LOADING_AGENCY_SUCCESS = 'agency_form/LOADING_AGENCY_SUCCESS'
export const LOADING_AGENCY_FAILURE = 'agency_form/LOADING_AGENCY_FAILURE'

export const SAVING_AGENCY = 'agency_form/SAVING_AGENCY '
export const SAVED_AGENCY_SUCCESS = 'agency_form/SAVED_AGENCY_SUCCESS'
export const SAVED_AGENCY_FAILURE = 'agency_form/SAVED_AGENCY_FAILURE'

export const AGENCY_FORM_TOGGLE = 'agency_form/AGENCY_FORM_TOGGLE '
export const AGENCY_FORM_RESET = 'agency_form/AGENCY_FORM_RESET '
