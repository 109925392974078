import {
  LOADING_BRANDS,
  LOADING_BRANDS_SUCCESS,
  LOADING_BRANDS_FAILURE
} from '../constants/brandsTypes'

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case LOADING_BRANDS:
      return {
        ...state,
        [actions.orgId]: {
            loading: true,
            success: null,
            data: null
          }
        }
    case LOADING_BRANDS_SUCCESS:
      return {
        ...state,
        [actions.orgId]: {
          loading: false,
          success: true,
          data: actions.data ? [...actions.data] : []
        }
      }
    case LOADING_BRANDS_FAILURE:
      return {
        ...state,
        [actions.orgId]: {
          loading: false,
          success: false,
          errors: actions.errors
        }
      }
    default:
      return state
  }
}
