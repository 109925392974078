import APIClient from './client'
import { camelizeKeys, decamelizeKeys } from 'humps'

const getConnection = ( connectionId ) => {
  return APIClient().get(`/v1/connections/${connectionId}`)
    .then( response => camelizeKeys(response.data))
}

const getAllConnectionsUnderBrand = ( brandId ) => {
  return APIClient().get(`/v1/brands/${brandId}/connections`)
    .then( response => camelizeKeys(response.data))
}

const createNewConnection = ({ brandId, payload }) => {
  return APIClient().post(`/v1/brands/${brandId}/connections`, { connection: decamelizeKeys(payload) })
    .then( response => camelizeKeys(response.data))
}

const updateExistingConnection = ({ connectionId, payload }) => {
  return APIClient().put(`/v1/connections/${connectionId}`,  { connection: decamelizeKeys(payload) })
    .then( response => camelizeKeys(response.data))
}

const getConnectionHealthStatuses = ( connectionId ) => {
  return APIClient().get(`/v1/connections/${connectionId}/health_statuses`)
    .then( response => camelizeKeys(response.data))
}

const getConnectionAdAccountHealthStatuses = ( connectionId ) => {
  return APIClient().get(`/v1/connections/${connectionId}/ad_account_health_statuses`)
    .then( response => camelizeKeys(response.data))
}
const getConnectionCrMatchHealthStatuses = ( connectionId ) => {
  return APIClient().get(`/v1/connections/${connectionId}/cr_match_health_statuses`)
    .then( response => camelizeKeys(response.data))
}

const getConnectionVersions = ( connectionId ) => {
  return APIClient().get(`/v1/connections/${connectionId}/versions`)
    .then( response => camelizeKeys(response.data))
}

// TikTok
const getConnectionTTCrMatchHealthStatuses = ( connectionId ) => {
  return APIClient().get(`/v1/tik_tok/connections/${connectionId}/cr_matches`)
    .then( response => camelizeKeys(response.data))
}

const getConnectionTTHealthStatuses = ( connectionId ) => {
  return APIClient().get(`/v1/tik_tok/connections/${connectionId}/health_statuses`)
    .then( response => camelizeKeys(response.data))
}

const connectionAPIs = {
  createNewConnection,
  getAllConnectionsUnderBrand,
  getConnection,
  getConnectionAdAccountHealthStatuses,
  getConnectionCrMatchHealthStatuses,
  getConnectionHealthStatuses,
  getConnectionVersions,
  updateExistingConnection,
  // TikTok
  getConnectionTTCrMatchHealthStatuses,
  getConnectionTTHealthStatuses
}

export default connectionAPIs
