import {
  CF_RESET,
  CF_LOADING_BRAND,
  CF_LOADING_BRAND_SUCCESS,
  CF_LOADING_BRAND_FAILURE,
  CF_LOADING_CONNECTION,
  CF_LOADING_CONNECTION_SUCCESS,
  CF_LOADING_CONNECTION_FAILURE,
  CF_LOADING_VERSIONS,
  CF_LOADING_VERSIONS_SUCCESS,
  CF_LOADING_VERSIONS_FAILURE,
  CF_LOADING_HEALTH_STATUSES,
  CF_LOADING_HEALTH_STATUSES_SUCCESS,
  CF_LOADING_HEALTH_STATUSES_FAILURE,
  CF_LOADING_AD_ACCOUNT_HEALTH_STATUSES,
  CF_LOADING_AD_ACCOUNT_HEALTH_STATUSES_SUCCESS,
  CF_LOADING_AD_ACCOUNT_HEALTH_STATUSES_FAILURE,
  CF_LOADING_CR_MATCH_HEALTH_STATUSES,
  CF_LOADING_CR_MATCH_HEALTH_STATUSES_SUCCESS,
  CF_LOADING_CR_MATCH_HEALTH_STATUSES_FAILURE,
  CF_LOADING_XPO_ADMIN_LOCATIONS,
  CF_LOADING_XPO_ADMIN_LOCATIONS_SUCCESS,
  CF_LOADING_XPO_ADMIN_LOCATIONS_FAILURE,
  CF_SAVING_CONNECTION,
  CF_SAVED_NEW_SUCCESS,
  CF_SAVED_EXISTING_SUCCESS,
  CF_SAVED_FAILURE,
  // TikTok
  CF_LOADING_TT_HEALTH_STATUSES,
  CF_LOADING_TT_HEALTH_STATUSES_SUCCESS,
  CF_LOADING_TT_HEALTH_STATUSES_FAILURE,
  CF_LOADING_TT_CR_MATCH_HEALTH_STATUSES,
  CF_LOADING_TT_CR_MATCH_HEALTH_STATUSES_SUCCESS,
  CF_LOADING_TT_CR_MATCH_HEALTH_STATUSES_FAILURE
} from '../constants/connectionFormTypes'
import merge from 'lodash/merge'

export default function reducer(state = { brand: null, connection: null }, actions) {
  switch (actions.type) {
    case CF_RESET:
      return {
        brand: null,
        connection: null
      }
    case CF_LOADING_BRAND:
      return {
        ...state,
        brand: {
            loading: true,
            success: null,
            data: null
          }
        }
    case CF_LOADING_BRAND_SUCCESS:
      return {
        ...state,
        brand: {
          loading: false,
          success: true,
          data: actions.data ? actions.data : []
        }
      }
    case CF_LOADING_BRAND_FAILURE:
      return {
        ...state,
        brand: {
          loading: false,
          success: false,
          errors: actions.errors
        }
      }
      case CF_LOADING_CONNECTION:
        return {
          ...state,
          connection: {
              loading: true,
              success: null,
              data: null
            }
          }
      case CF_LOADING_CONNECTION_SUCCESS:
        return {
          ...state,
          connection: {
            loading: false,
            success: true,
            data: actions.data ? actions.data : []
          }
        }
      case CF_LOADING_CONNECTION_FAILURE:
        return {
          ...state,
          connection: {
            loading: false,
            success: false,
            errors: actions.errors
          }
        }
        case CF_SAVING_CONNECTION:
          return {
            ...state,
            formState: {
              inProgress: true,
              success: null,
              errors: null
            }
          }
        case CF_SAVED_NEW_SUCCESS:
          return {
            ...state,
            formState: {
              inProgress: false,
              success: true,
              data: actions.data ? actions.data : {},
              errors: null
            }
          }
        case CF_SAVED_EXISTING_SUCCESS:
          return {
            ...state,
            formState: {
              inProgress: false,
              success: true,
              errors: null,
              requestId: Math.random()
            }
          }
        case CF_SAVED_FAILURE:
          return {
            ...state,
            formState: {
              inProgress: false,
              success: false,
              errors:  actions.errors,
              requestId: Math.random()
            }
          }
        // VERSIONS
        case CF_LOADING_VERSIONS:
          return {
            ...state,
            versions: {
              loading: true,
              success: false,
              errors: null
            }
          }
        case CF_LOADING_VERSIONS_SUCCESS:
          return {
            ...state,
            versions: {
              loading: false,
              success: true,
              data: actions.data ? actions.data : []
            }
          }
        case CF_LOADING_VERSIONS_FAILURE:
          return {
            ...state,
            versions: {
              loading: false,
              success: false,
              errors: actions.errors
            }
          }
        // END VERSIONS
        case CF_LOADING_HEALTH_STATUSES:
          const healthStatuses = state.healthStatuses || {}

          return {
            ...state,
            healthStatuses: merge({}, healthStatuses, { loading: true })
          }
        case CF_LOADING_HEALTH_STATUSES_SUCCESS:
          return {
            ...state,
            healthStatuses: {
              loading: false,
              success: true,
              data: actions.data ? actions.data : []
            }
          }
        case CF_LOADING_HEALTH_STATUSES_FAILURE:
          return {
            ...state,
            healthStatuses: {
              loading: false,
              success: false,
              errors: actions.errors
            }
          }
        case CF_LOADING_AD_ACCOUNT_HEALTH_STATUSES:
          const adAccountHealthStatuses = state.adAccountHealthStatuses || {}

          return {
            ...state,
            adAccountHealthStatuses: merge({}, adAccountHealthStatuses, { loading: true })
          }
        case CF_LOADING_AD_ACCOUNT_HEALTH_STATUSES_SUCCESS:
          return {
            ...state,
            adAccountHealthStatuses: {
              loading: false,
              success: true,
              data: actions.data ? actions.data : []
            }
          }
        case CF_LOADING_AD_ACCOUNT_HEALTH_STATUSES_FAILURE:
          return {
            ...state,
            adAccountHealthStatuses: {
              loading: false,
              success: false,
              errors: actions.errors
            }
          }
        // ---- CR USER HEALTH STATUSES
        case CF_LOADING_CR_MATCH_HEALTH_STATUSES:
          const crUsersHealthStatuses = state.crUsersHealthStatuses || {}
          return {
            ...state,
            crUsersHealthStatuses: merge({}, crUsersHealthStatuses, { loading: true })
          }
        case CF_LOADING_CR_MATCH_HEALTH_STATUSES_SUCCESS:
          return {
            ...state,
            crUsersHealthStatuses: {
              loading: false,
              success: true,
              data: actions.data ? actions.data : []
            }
          }
        case CF_LOADING_CR_MATCH_HEALTH_STATUSES_FAILURE:
          return {
            ...state,
            crUsersHealthStatuses: {
              loading: false,
              success: false,
              errors: actions.errors
            }
          }
        // END ---- CR USER HEALTH STATUSES
        case CF_LOADING_XPO_ADMIN_LOCATIONS:
          return {
            ...state,
            xpoAdminLocations: {
              loading: true
            }
          }
        case CF_LOADING_XPO_ADMIN_LOCATIONS_SUCCESS:
          return {
            ...state,
            xpoAdminLocations: {
              loading: false,
              success: true,
              data: actions.data ? actions.data : []
            }
          }
        case CF_LOADING_XPO_ADMIN_LOCATIONS_FAILURE:
          return {
            ...state,
            xpoAdminLocations: {
              loading: false,
              success: false,
              data: [], // Set to render an empty list + error
              errors: actions.errors
            }
          }
        // TikTok - Healthchecks
        case CF_LOADING_TT_HEALTH_STATUSES:
          const ttHealthStatuses = state.healthStatuses || {}

          return {
            ...state,
            ttHealthStatuses: merge({}, ttHealthStatuses, { loading: true })
          }
        case CF_LOADING_TT_HEALTH_STATUSES_SUCCESS:
          return {
            ...state,
            ttHealthStatuses: {
              loading: false,
              success: true,
              data: actions.data ? actions.data : []
            }
          }
        case CF_LOADING_TT_HEALTH_STATUSES_FAILURE:
          return {
            ...state,
            ttHealthStatuses: {
              loading: false,
              success: false,
              errors: actions.errors
            }
          }
        // TikTok - CR USERS
        case CF_LOADING_TT_CR_MATCH_HEALTH_STATUSES:
          const ttCRUsersHealthStatuses = state.crUsersHealthStatuses || {}
          return {
            ...state,
            ttCRUsersHealthStatuses: merge({}, ttCRUsersHealthStatuses, { loading: true })
          }
        case CF_LOADING_TT_CR_MATCH_HEALTH_STATUSES_SUCCESS:
          return {
            ...state,
            ttCRUsersHealthStatuses: {
              loading: false,
              success: true,
              data: actions.data ? actions.data : []
            }
          }
        case CF_LOADING_TT_CR_MATCH_HEALTH_STATUSES_FAILURE:
          return {
            ...state,
            ttCRUsersHealthStatuses: {
              loading: false,
              success: false,
              errors: actions.errors
            }
          }
    default:
      return state
  }
}
