import APIClient from './client'
import { camelizeKeys } from 'humps'

const getBrand = (brandId) => {
  return APIClient().get(`/v1/brands/${brandId}`)
    .then( response => camelizeKeys(response.data))
}

const getAllBrandsUnderOrg = ( orgId ) => {
  return APIClient().get(`/v1/orgs/${orgId}/brands`)
    .then( response => camelizeKeys(response.data))
}
const BrandsAPI = { getAllBrandsUnderOrg, getBrand }
export default BrandsAPI 
