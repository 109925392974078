
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { get, find, isNil } from 'lodash'
import { Badge, Button } from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

// CSS
import styles from './Sidebar.module.css'

// Constants
import { GLOBAL_CONNECTIONS_CSV_RECEIVED } from '../redux/constants/csvExportTypes'

// Selectors
const currentOrgNameSelector = (state, ownProps) => {
  const orgs = get(state, 'orgs.data', [])
  const currentOrgId = ownProps.match.params.orgId
  const currentOrg = find(orgs, (org) => {
    return parseInt(currentOrgId) === parseInt(org.id)
  })

  return get(currentOrg,'name','');
}

const csvExportKeySelector= (state, ownProps) => {
  const orgId = get(ownProps,'match.params.orgId', null)
  return get(state,`csvExport.${orgId}.cacheKey`, null);
}

class CsvDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { downloadDisabled: false }
  }

  componentDidUpdate(prevProps) {
    // Track changes in the CSV Download
    if(!isNil(this.props.csvExportKey) && this.props.csvExportKey !== prevProps.csvExportKey) {
      const fileName = this.props.csvExportKey
      const downloadUrl = `${process.env.REACT_APP_CONNECTOR_HOST}/v1/csv_export/${fileName}`

      // OK. I know this is a bit weird!
      // The only way at the moment to force the attachment download in the same window with no refresh is creating a link with
      // download attribute and clicking on it.
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = downloadUrl;
      a.download = fileName;
      a.click();

      this.unsubscribeCsvDownload()
      this.setState({downloadDisabled: false})
    }

  }

  downloadCsv() {
    // Download is NOT in progress
    if(!this.state.downloadDisabled) {
      this.subscribeCsvDownload()
      this.setState({downloadDisabled: true})
    }
  }

  subscribeCsvDownload() {
    const orgId = this.props.match.params.orgId
    const { dispatch } = this.props

    // Subscribe to the Notifications Channel
    dispatch({
      channel: 'CsvExportChannel',
      params: { org_id: orgId },
      received: data => dispatch({
        type: GLOBAL_CONNECTIONS_CSV_RECEIVED,
        data: data
      })
    })
  }

  unsubscribeCsvDownload() {
    const orgId = this.props.match.params.orgId
    const { dispatch } = this.props

    dispatch({
      channel: 'CsvExportChannel',
      params: { org_id: orgId },
      leave: true
    })
  }

  render() {
    const { currentOrgName } = this.props;

    return (
      <div className={styles.csvDownload}>
        <Button color="primary" disabled={this.state.downloadDisabled} className={styles.csvDownloadButtom} onClick={ () => this.downloadCsv() }>
          <FontAwesomeIcon icon={faDownload} /> {currentOrgName}'s' Connections
          {this.state.downloadDisabled &&
            <Badge color="warning" className={styles.downloadProgress}>In progress ...</Badge>
          }
        </Button>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentOrgName: currentOrgNameSelector(state, ownProps),
  csvExportKey: csvExportKeySelector(state, ownProps)
})

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(CsvDownload))
