import APIClient from './client'
import { camelizeKeys, decamelizeKeys } from 'humps'

const getAllAgenciesUnderBrand = ( brandId ) => {
  return APIClient().get(`/v1/brands/${brandId}/agencies`)
    .then( response => camelizeKeys(response.data))
}

const getAgency = ( agencyId ) => {
  return APIClient().get(`/v1/agencies/${agencyId}`)
    .then( response => camelizeKeys(response.data))
}

const createNewAgency = ({ brandId, payload }) => {
  return APIClient().post(`/v1/brands/${brandId}/agencies`, { agency: decamelizeKeys(payload) })
    .then( response => camelizeKeys(response.data))
}

const updateExistingAgency = ({ agencyId, payload }) => {
  return APIClient().put(`/v1/agencies/${agencyId}`,  { agency: decamelizeKeys(payload) })
    .then( response => camelizeKeys(response.data))
}

const AgenciesAPI = {
  getAgency,
  getAllAgenciesUnderBrand,
  createNewAgency,
  updateExistingAgency
}

export default AgenciesAPI
