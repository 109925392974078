import React from 'react';
import {
  Card,
  CardBody
} from "reactstrap";
import { Box } from "react-feather";
import * as jose from 'jose'
import Cookies from 'universal-cookie';
import styles from './SignIn.module.css'

window.onSignIn = (response) => {
  const cookies = new Cookies();

  try {
    const decodedToken = jose.decodeJwt(response.credential);

    // Check if the token is expired
    if(decodedToken.exp > new Date().getTime() / 1000) {
      cookies.set('idToken', response.credential, { path: '/' });
      cookies.set('email',  decodedToken.email, { path: '/' });
  
      window.location = '/' // Redirect to home page
    } else {
      console.log('Token is expired')
      cookies.remove('idToken',{ path: '/' })
      cookies.remove('email',{ path: '/' })
      window.location = '/'
    }

  } catch (e) {
    console.log(e)
  }
}

class SignIn extends React.Component {
  componentDidMount() {
    this.loadLogInButton()
  }

  loadLogInButton = () => {
   
      /*global google*/
      google.accounts.id.initialize({
        client_id: "219288133199-18jqnaslso0891vehskcvsdvd76ugk9d.apps.googleusercontent.com",
        callback: window.onSignIn,
      });
      
      window.google.accounts.id.renderButton(
        document.getElementById("g_id_signin"), //this is a ref hook to the div in the official example
        {
          theme: "filled_blue", 
          type: "standard",
          shape: "pill",
          text: "signin",
          size: "large",
          logo_alignment: "left",
          context: "use",
          width: 250,
          height: 50
        }
      );
      google.accounts.id.prompt(); // also display the One Tap dialog
  }

  render() {
    return (
      <React.Fragment>
        <div className={styles.logoBox + " text-center mt-4"}>
          <h2><Box className="align-middle text-primary" size={34} /> ConnectorApp</h2>
          <p className="lead">Sign in to your account to continue</p>
        </div>
        <Card>
          <CardBody className={styles.gLogin}>
              <div id="g_id_signin"></div>
          </CardBody>
        </Card>
      </React.Fragment>
  )}
}

export default SignIn
