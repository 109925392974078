import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from '@sentry/browser';

import App from "./App";

Sentry.init({dsn: process.env.REACT_APP_SENTRY_AUTH_URL });

ReactDOM.render(<App />, document.getElementById("root"));
