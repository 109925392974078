import agencies from './agencies-apis'
import brands from './brands-apis'
import connections from './connections-apis'
import fbPages from './fb-pages-apis'
import fbAdAccounts from './fb-ad-accounts-apis'
import orgs from './orgs-apis'
import xpoAdmin from './xpo-admin-locations'
import ttAdvertisers from './tt-advertisers-apis'

const Api = {
 agencies,
 brands,
 connections,
 fbAdAccounts,
 fbPages,
 orgs,
 xpoAdmin,
 ttAdvertisers
}

export default Api;
