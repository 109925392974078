import {
  LOADING_CONNECTIONS,
  LOADING_CONNECTIONS_SUCCESS,
  LOADING_CONNECTIONS_FAILURE
} from '../constants/connectionsTypes'

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case LOADING_CONNECTIONS:
      return {
        ...state,
        [actions.brandId]: {
            loading: true,
            success: null,
            data: null
          }
        }
    case LOADING_CONNECTIONS_SUCCESS:
      return {
        ...state,
        [actions.brandId]: {
          loading: false,
          success: true,
          data: actions.data ? [...actions.data] : []
        }
      }
    case LOADING_CONNECTIONS_FAILURE:
      return {
        ...state,
        [actions.brandId]: {
          loading: false,
          success: false,
          errors: actions.errors
        }
      }
    default:
      return state
  }
}
