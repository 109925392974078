const environment = {
    development: {
      isProduction: false
    },
    production: {
      isProduction: true
    }
  }[process.env.NODE_ENV || 'development']

  module.exports = Object.assign(
    {
        connectionServiceAPI: process.env.REACT_APP_CONNECTOR_HOST || 'http://localhost:3001',
    },
    environment
  )
