import {
  LOADING_ORGS,
  LOADING_ORGS_SUCCESS,
  LOADING_ORGS_FAILURE
} from '../constants/orgsTypes'

const initialState = {
  loading: true,
  success: null,
  data: null,
  errors: null
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case LOADING_ORGS:
      return { ...state, loading: true }
    case LOADING_ORGS_SUCCESS:
      return {
        ...state,
        success: true,
        data: actions.data ? [...actions.data] : [],
        loading: false
      }
    case LOADING_ORGS_FAILURE:
      return {
        ...state,
        success: false,
        errors: actions.errors,
        loading: false
      }
    default:
      return state
  }
}
