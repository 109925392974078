export const CF_RESET = 'connection_form/RESET'

export const CF_LOADING_BRAND = 'connection_form/LOAD_BRAND'
export const CF_LOADING_BRAND_SUCCESS = 'connection_form/LOAD_BRAND_SUCCESS'
export const CF_LOADING_BRAND_FAILURE = 'connection_form/LOAD_BRAND_FAILURE'

export const CF_LOADING_HEALTH_STATUSES = 'connection_form/LOAD_HEALTH_STATUSES'
export const CF_LOADING_HEALTH_STATUSES_SUCCESS = 'connection_form/LOAD_HEALTH_STATUSES_SUCCESS'
export const CF_LOADING_HEALTH_STATUSES_FAILURE = 'connection_form/LOAD_HEALTH_STATUSES_FAILURE'

export const CF_LOADING_AD_ACCOUNT_HEALTH_STATUSES = 'connection_form/LOAD_AD_ACCOUNT_HEALTH_STATUSES'
export const CF_LOADING_AD_ACCOUNT_HEALTH_STATUSES_SUCCESS = 'connection_form/LOAD_AD_ACCOUNT_HEALTH_STATUSES_SUCCESS'
export const CF_LOADING_AD_ACCOUNT_HEALTH_STATUSES_FAILURE = 'connection_form/LOAD_AD_ACCOUNT_HEALTH_STATUSES_FAILURE'

export const CF_LOADING_CR_MATCH_HEALTH_STATUSES = 'connection_form/LOAD_CR_MATCH_HEALTH_STATUSES'
export const CF_LOADING_CR_MATCH_HEALTH_STATUSES_SUCCESS = 'connection_form/LOAD_CR_MATCH_HEALTH_STATUSES_SUCCESS'
export const CF_LOADING_CR_MATCH_HEALTH_STATUSES_FAILURE = 'connection_form/LOAD_CR_MATCH_HEALTH_STATUSES_FAILURE'

export const CF_LOADING_CONNECTION = 'connection_form/LOAD_CONNECTION '
export const CF_LOADING_CONNECTION_SUCCESS = 'connection_form/LOAD_CONNECTION_SUCCESS'
export const CF_LOADING_CONNECTION_FAILURE = 'connection_form/LOAD_CONNECTION_FAILURE'
export const CF_SAVING_CONNECTION = 'connection_form/SAVING_CONNECTION '
export const CF_SAVED_NEW_SUCCESS = 'connection_form/SAVED_NEW_SUCCESS'
export const CF_SAVED_EXISTING_SUCCESS = 'connection_form/SAVED_EXISTING_SUCCESS'
export const CF_SAVED_FAILURE = 'connection_form/SAVED_FAILURE'

export const CF_LOADING_XPO_ADMIN_LOCATIONS = 'connection_form/LOAD_XPO_ADMIN_LOCATIONS'
export const CF_LOADING_XPO_ADMIN_LOCATIONS_SUCCESS = 'connection_form/LOAD_XPO_ADMIN_LOCATIONS_SUCCESS'
export const CF_LOADING_XPO_ADMIN_LOCATIONS_FAILURE = 'connection_form/LOAD_XPO_ADMIN_LOCATIONS_FAILURE'

export const CF_LOADING_VERSIONS = 'connection_form/LOAD_VERSIONS'
export const CF_LOADING_VERSIONS_SUCCESS = 'connection_form/LOAD_VERSIONS_SUCCESS'
export const CF_LOADING_VERSIONS_FAILURE = 'connection_form/LOAD_VERSIONS_FAILURE'

// TikTok
export const CF_LOADING_TT_HEALTH_STATUSES = 'connection_form/LOAD_TT_HEALTH_STATUSES'
export const CF_LOADING_TT_HEALTH_STATUSES_SUCCESS = 'connection_form/LOAD_TT_HEALTH_STATUSES_SUCCESS'
export const CF_LOADING_TT_HEALTH_STATUSES_FAILURE = 'connection_form/LOAD_TT_HEALTH_STATUSES_FAILURE'

export const CF_LOADING_TT_CR_MATCH_HEALTH_STATUSES = 'connection_form/LOAD_TT_CR_MATCH_HEALTH_STATUSES'
export const CF_LOADING_TT_CR_MATCH_HEALTH_STATUSES_SUCCESS = 'connection_form/LOAD_TT_CR_MATCH_HEALTH_STATUSES_SUCCESS'
export const CF_LOADING_TT_CR_MATCH_HEALTH_STATUSES_FAILURE = 'connection_form/LOAD_TT_CR_MATCH_HEALTH_STATUSES_FAILURE'
