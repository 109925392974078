import axios from 'axios'
import config from './config'
import Cookies from 'universal-cookie';

// const APIClient = (userData) => {
//   if(userData === null || userData === undefined){
//     throw 'Not authenticated request detected'
//   }
//
//   return axios.create({
//     baseURL: config.connectionServiceAPI,
//     timeout: 3000,
//
//   });
// }

const cookies = new Cookies();

const APIClient = () => {
  return axios.create({
    baseURL: config.connectionServiceAPI,
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + cookies.get('idToken',{ path: '/' }),
      'Authorized-User-Email': cookies.get('email',{ path: '/' })
    }
  });
}

export default APIClient;
