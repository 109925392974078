import Api from '../../apis'

import {
  LOADING_BRANDS,
  LOADING_BRANDS_SUCCESS,
  LOADING_BRANDS_FAILURE
} from '../constants/brandsTypes'

export function loadBrands( orgId ) {
  return dispatch => {
    dispatch({ type: LOADING_BRANDS, orgId })
    return Api.brands
      .getAllBrandsUnderOrg(orgId)
      .then(data => {
        dispatch({ type: LOADING_BRANDS_SUCCESS, orgId, data: data, })
      })
      .catch(error => {
        dispatch({ type: LOADING_BRANDS_FAILURE, orgId, errors: { errorMessage: error.message } })
      })
  }
}
