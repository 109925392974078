
import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { get, isNil, isUndefined, includes } from 'lodash'
import { Badge, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Box } from "react-feather";
import {
  List as ListIcon,
} from "react-feather";

import async from "../components/Async";
import routes from "../routes/index";
import { loadBrands } from '../redux/actions/brandsActions'

import CsvDownload from './CsvDownload'
import SyncBm from './SyncBm'

const BrandDashboard = async(() => import("../pages/brands/BrandDashboard"));

// Combine the brands in a Menu Format
// @return
// const brandsList = [{
//   path: "/", //org/:org_id/brands/:brand_id
//   name: "Brands",
//   icon: ListIcon,
//   children: [
//     {
//       path: "/org/:org_id/brand/:brand_id",
//       name: "Club Pilates (582)",
//       component: BrandDashboard
//     },
//    ...
// }];
const brandsList = (orgId, brands) => {
  const children = brands.map(brand => {
    return {
      path: '/org/' + orgId + '/brand/' + brand.id + '/connections',
      matchPaths: [
        '/org/' + orgId + '/brand/' + brand.id + '/connections',
        '/org/' + orgId + '/brand/' + brand.id + '/associations'
      ],
      name: brand.name + " (" + brand.connectionsCount + ")",
      component: BrandDashboard
    }
  })

  return [{
    path: "/", //org/:org_id/brands/:brand_id
    name: "Brands",
    icon: ListIcon,
    children: children
  }]
}

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to
  }) => {
    const getSidebarItemClass = path => {
      return location.pathname.indexOf(path) !== -1 ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link collapsed"}
          onClick={onClick}
          aria-expanded={"true"}
        >
          <Icon size={18} className="align-middle mr-3" />
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={true}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to, matchPaths }) => {
    const getSidebarItemClass = path => {
      return includes(matchPaths, location.pathname) ? "active" : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
          {name}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

// SELECTORS
const brandsLoadingSelector = (state, ownProps) => (
  get(state, ['brands',ownProps.match.params.orgId, 'loading'] , null)
)

const brandsSelector = (state, ownProps) => {
  return get(state, ['brands',parseInt(ownProps.match.params.orgId), 'data'], null)
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggle = index => {
    // Collapse all elements
    Object.keys(this.state).forEach(
      item =>
        this.state[index] ||
        this.setState(() => ({
          [item]: false
        }))
    );

    // Toggle selected element
    this.setState(state => ({
      [index]: !state[index]
    }));
  };

  shouldPerformInitialBrandsFetch() {
    return !isNil(this.props.match.params.orgId) && // orgId is set
      isNil(this.props.brandsLoading) // Brands for this org were never fetched
  }

  shouldChangeURLtoContainBrandId() {
    return !isNil(this.props.match.params.orgId) && // orgId is set
      (isNil(this.props.match.params.brandId) || isUndefined(this.props.match.params.brandId)) &&  // NO brandId in the URL params
      !isNil(this.props.brands) //
  }

  bootstrapComponent() {
    // Execute on Initial Load -> Default to the First Brand
    if(this.shouldChangeURLtoContainBrandId()) {
      this.props.history.push("/org/" + this.props.match.params.orgId + "/brand/" + this.props.brands[0].id  + "/connections" )
    }

    // Fetch Brands if there are none
    if(this.shouldPerformInitialBrandsFetch()){
      this.props.fetchBrands(this.props.match.params.orgId)
    }
  }

  componentDidMount() {
    /* Open collapse element that matches current url */
    const pathName = this.props.location.pathname;
    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) > 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/" ? true : false;

      this.setState(() => ({
        [index]: isActive || isOpen || isHome
      }));
    });

    this.bootstrapComponent()
  }


  componentDidUpdate(prevProps) {
    this.bootstrapComponent()
  }

  render() {
    const { sidebar, brands } = this.props;
    const orgId = this.props.match.params.orgId

    return (
      <nav
        className={
          "sidebar" +
          (!sidebar.isOpen ? " toggled" : "") +
          (sidebar.isSticky ? " sidebar-sticky" : "")
        }
      >
        <div className="sidebar-content">
          <PerfectScrollbar>
            <a className="sidebar-brand" href="/">
              <Box className="align-middle text-primary" size={24} />{" "}
              <span className="align-middle">ConnectorApp</span>
            </a>

            <ul className="sidebar-nav">
              { !brands &&
                <React.Fragment>
                  Loading Brands ...
                </React.Fragment>
              }
              { brands &&
                brandsList(orgId, brands).map((category, index) => {
                  return (
                    <React.Fragment key={index}>
                      {category.header ? (
                        <li className="sidebar-header">{category.header}</li>
                      ) : null}

                      {category.children ? (
                        <SidebarCategory
                          name={category.name}
                          badgeColor={category.badgeColor}
                          badgeText={category.badgeText}
                          icon={category.icon}
                          to={category.path}
                          matchPaths={category.matchPaths}
                          isOpen={this.state[index]}
                          onClick={() => this.toggle(index)}
                        >
                          {category.children.map((route, index) => (
                            <SidebarItem
                              key={index}
                              name={route.name}
                              to={route.path}
                              matchPaths={route.matchPaths}
                              badgeColor={route.badgeColor}
                              badgeText={route.badgeText}
                            />
                          ))}
                        </SidebarCategory>
                      ) : (
                        <SidebarItem
                          name={category.name}
                          to={category.path}
                          matchPaths={category.matchPaths}
                          icon={category.icon}
                          badgeColor={category.badgeColor}
                          badgeText={category.badgeText}
                        />
                      )}

                      <CsvDownload/>
                      <SyncBm/>
                    </React.Fragment>
                  );
                })
              }
            </ul>
          </PerfectScrollbar>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  sidebar: state.sidebar,
  brandsLoading: brandsLoadingSelector(state, ownProps),
  brands: brandsSelector(state, ownProps),
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBrands: (orgId) => dispatch(loadBrands(orgId))
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
