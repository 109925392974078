
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { get, isNil } from 'lodash'
import { Badge, Button } from "reactstrap";
import { toastr } from "react-redux-toastr";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

// CSS
import styles from './Sidebar.module.css'

// Constants
import { SYNC_WITH_BM_NOTIFICATION_RECEIVED } from '../redux/constants/syncWithBmTypes'

// Selectors
const adAccountsSyncStatusSelector = (state) => {
  return get(state,'bm.adAccountsSync.syncedAt', null);
}
const fbPagesSyncStatusSelector = (state) => {
  return get(state,'bm.pagesSync.syncedAt', null);
}

class SyncBm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { syncInProgress: false }
  }

  componentDidUpdate(prevProps) {
    const { fbPagesSyncStatus, adAccountsSyncStatus } = this.props
    const prevFbPagesSyncStatus = prevProps.fbPagesSyncStatus
    const prevAdAccountsSyncStatus = prevProps.adAccountsSyncStatus

    if(!isNil(fbPagesSyncStatus) && !isNil(adAccountsSyncStatus) && (fbPagesSyncStatus !== prevFbPagesSyncStatus || adAccountsSyncStatus !== prevAdAccountsSyncStatus)) {
      this.unsubscribeBMSync()
      this.setState({syncInProgress: false})

      const options = {
        timeOut: 2000,
        showCloseButton: true,
        progressBar: true,
        position: 'top-center'
      };
      toastr.success('Business Manager Sync','COMPLETED', options)
    }

  }

  downloadCsv() {
    // Download is NOT in progress
    if(!this.state.syncInProgress) {
      this.syncWithBM()
      this.setState({syncInProgress: true})
    }
  }

  syncWithBM() {
    const { dispatch } = this.props

    // Subscribe to the Notifications Channel
    dispatch({
      channel: 'SyncBmChannel',
      received: data => dispatch({
        type: SYNC_WITH_BM_NOTIFICATION_RECEIVED,
        data: data
      })
    })
  }

  unsubscribeBMSync() {
    const { dispatch } = this.props

    dispatch({
      channel: 'SyncBmChannel',
      leave: true
    })
  }

  render() {

    return (
      <div className={styles.syncBm}>
        <Button color="primary" disabled={this.state.syncInProgress} className={styles.syncBmButton} onClick={ () => this.downloadCsv() }>
          <FontAwesomeIcon icon={faSync} /> Sync BM Pages & Ad Accounts
          {this.state.syncInProgress &&
            <Badge color="warning" className={styles.downloadProgress}>In progress ...</Badge>
          }
        </Button>
        <small className={"text-muted " + styles.syncBmMessage}>*Do not sync more then 5 times an hour due to API limits.</small>

      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  adAccountsSyncStatus: adAccountsSyncStatusSelector(state),
  fbPagesSyncStatus: fbPagesSyncStatusSelector(state)
})

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(SyncBm))
