import { combineReducers } from "redux";
import { reducer as toastr } from "react-redux-toastr";
import { reducer as formReducer } from 'redux-form'
import agencies from './agenciesReducer'
import agencyForm from './agencyFormReducer'
import authentication from './authenticationReducers'
import brands from './brandsReducers'
import csvExport from './csvExportReducers'
import connections from './connectionsReducers'
import connectionForm from './connectionFormReducers'
import fbAdAccounts from './fbAdAccountsReducers'
import fbPages from './fbPagesReducers'
import ttAdvertisers from './ttAdvertisersReducers'
import orgs from './orgsReducers'
import sidebar from "./sidebarReducers";
import bm from './syncWithBmReducers'


import {
  SIGN_OUT_USER
} from '../constants/authenticationTypes'

const appReducer = combineReducers({
  agencies,
  agencyForm,
  authentication,
  brands,
  bm,
  csvExport,
  connections,
  connectionForm,
  fbAdAccounts,
  fbPages,
  form: formReducer,
  orgs,
  toastr,
  sidebar,
  ttAdvertisers
});

/* Clear the Store on Log Out */
const rootReducer = (state, action) => {
 if (action.type === SIGN_OUT_USER) {
   state = undefined
 }

 return appReducer(state, action)
}

export default rootReducer;
