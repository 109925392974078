import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as jose from 'jose'
import Cookies from 'universal-cookie';
import {
  brands as brandsRoutes,
  page as pageRoutes,
  connections as connectionsRoutes
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import MovieScreenLayout from "../layouts/MovieScreen";
import Page404 from "../pages/auth/Page404";
import AuthLayout from "../layouts/Auth";
import ScrollToTop from "../components/ScrollToTop";

/* Actions */
import { logInUser } from '../redux/actions/authenticationActions'

class Routes extends Component{
  _isMounted = false

  constructor(props) {
    super(props);
    this.state = {
      loadingGsiClient: true,
      isLoggedIn: null
    }
  }

  loadGapis = () => {
    window.onGoogleLibraryLoad = () => {
      const cookies = new Cookies();
      const idToken = cookies.get('idToken',{ path: '/' })
      
      if(idToken) {
      
        try {
          const decodedToken = jose.decodeJwt(idToken);

          const userData = {
            id: decodedToken.email,
            name: decodedToken.name,
            imageUrl: decodedToken.picture,
            email: decodedToken.email,
            idToken: idToken
          }
  
          this.props.logInUser(userData)
          this.setState({
            loadingGsiClient: false,
            isLoggedIn: true
          })
          
        } catch (e) {
          // If the token is invalid, remove it from the cookies
          cookies.remove('idToken',{ path: '/' })
          cookies.remove('email',{ path: '/' })
          window.location = '/'
          return
        }
      } else {
        this.setState({
          loadingGsiClient: false,
          isLoggedIn: false
        })
      }
    }
  }

  componentDidMount() {
    this.loadGapis()
  }

  componentWillUnmount() {
    this._isMounted = false
    // if (window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
    //   window.gapi.auth2.getAuthInstance().signOut()
    // }
  }

  childRoutes(Layout, routes, privatePage){

    return (
      routes.map(({ children, path, component: Component }, index) =>
        children ? (
          // Route item with children
          children.map(({ path, component: Component }, index) => (
            <Route
              key={index}
              path={path}
              exact
              render={props => ((privatePage && this.state.isLoggedIn) || !privatePage) ?
                (!privatePage && this.state.isLoggedIn) ?
                  <Redirect
                    to={{
                      pathname: "/",
                      state: { from: props.location }
                    }}
                  />
                : (
                <Layout>
                  <Component {...props} />
                </Layout>
              ) : (
                <Redirect
                  to={{
                    pathname: "/auth/sign-in",
                    state: { from: props.location }
                  }}
                />
              )}
            />
          ))
        ) : (
          // Route item without children
          <Route
            key={index}
            path={path}
            exact
            render={props => (
              <Layout>
                <Component {...props} />
              </Layout>
            )}
          />
        )
      )
    )
  }

  render(){
    return (
      <React.Fragment>
          {this.state.loadingGsiClient && <div></div>}

          {!this.state.loadingGsiClient &&
            <Router>
              <ScrollToTop>
                <Switch>
                  {this.childRoutes(MovieScreenLayout, connectionsRoutes, true)}
                  {this.childRoutes(DashboardLayout, brandsRoutes, true)}
                  {this.childRoutes(AuthLayout, pageRoutes, false)}
                  <Route
                    render={() => (
                      <AuthLayout>
                        <Page404 />
                      </AuthLayout>
                    )}
                  />
                </Switch>
              </ScrollToTop>
            </Router>
          }
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logInUser: (userData) => dispatch(logInUser(userData))
  }
}

export default connect(null, mapDispatchToProps)(Routes)
