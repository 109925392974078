import Api from '../../apis'

import {
  LOADING_ORGS,
  LOADING_ORGS_SUCCESS,
  LOADING_ORGS_FAILURE
} from '../constants/orgsTypes'

export function loadOrgs() {
  return dispatch => {
    dispatch({ type: LOADING_ORGS })

    return Api.orgs
      .getAllOrgs()
      .then(data => {
        dispatch({ type: LOADING_ORGS_SUCCESS, data: data })
      })
      .catch(error => {
        dispatch({ type: LOADING_ORGS_FAILURE, errors: { errorMessage: error.message } })
      })
  }
}
