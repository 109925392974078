import async from "../components/Async";

import {
  Users as UsersIcon
} from "react-feather";

// Auth
import SignIn from "../pages/auth/SignIn";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

const BrandDashboard = async(() => import("../pages/brands/BrandDashboard"));
const ConnectionForm = async(() => import("../pages/connections/Form"));

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "12/24",
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ]
};

const brandsRoutes = {
  path: "/org/:orgId",
  name: "Brands",
  component: BrandDashboard,
  children: [
    {
      path: "/",
      name: "Brands",
      component: BrandDashboard,
    },
    {
      path: "/org/:orgId",
      name: "Brands",
      component: BrandDashboard,
    },
    {
      path: "/org/:orgId/brand/:brandId",
      name: "Brands",
      component: BrandDashboard
    },
    {
      path: "/org/:orgId/brand/:brandId/connections",
      name: "Connections",
      component: BrandDashboard
    },
    {
      path: "/org/:orgId/brand/:brandId/associations",
      name: "Associations",
      component: BrandDashboard
    }
  ]
};

const connectionsRoutes = {
  path: "/",
  name: "Forms",
  children: [
    {
      path: "/brand/:brandId/connection/new",
      name: "Connection Form",
      component: ConnectionForm
    },
    {
      path: "/brand/:brandId/connection/:connectionId",
      name: "Connection Form",
      component: ConnectionForm
    }
  ]
}

// Dashboard specific routes
export const brands = [
  brandsRoutes
];

export const connections = [
  connectionsRoutes
];

// Auth specific routes
export const page = [authRoutes];

// All routes
const defaultRoutes = [
  brandsRoutes
];
export default defaultRoutes
